/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// import FeaturedChallenges from '../FeaturedChallenges';
import CreateChallengeForm from "../CreateChallengeForm";
import Waiting from '../Waiting';

import { Layout, FormContainer } from '../CreateEvent/styles';
import { ChallengeTitle } from './styles';

import { getChallengeData } from '../../redux/actions';
import {withTranslation} from 'react-i18next';

class CreateChallenge extends Component {
  constructor() {
    super();
    this.state = {
      survivorChallengeData: {}
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getChallengeData, challengeData } = this.props;
    if(!_.isNull(nextProps.error)) {
      getChallengeData();
      this.setState({
        survivorChallengeData: {
          title: _.get(challengeData, 'title', ''),
          date: moment(_.get(challengeData, 'start_date', moment())).format('MM/DD/YYYY'),
          endDate: moment(_.get(challengeData, 'end_date', moment())).format('MM/DD/YYYY'),
          description: _.get(challengeData, 'body', ''),
          time: moment(_.get(challengeData, 'start_date', moment())).format('hh:mm a'),
          endTime: moment(_.get(challengeData, 'end_date', moment())).format('hh:mm a'),
          activityType: _.get(challengeData, 'event_type', 'Total Distance'),
          challengePointValue: _.get(challengeData, 'challenge_point', 'Tier 1: 25 points'),
          challengeMode: _.get(challengeData, 'challenge_mode', 'Team'),
          weeklyIncrement: _.get(challengeData, 'weekly_increment', 0),
          survivorAverage: _.get(challengeData, 'survivor_average', 0),
          imageName: _.get(challengeData, 'imageName', ''),
          image: _.get(challengeData, 'image', ''),
          companies: _.get(challengeData, 'companies', [])
        }
      });
    }
  }

  render() {
    const { createChallenge, isLoading, role, createLibrary, createActivityChallenge} = this.props;
    const MandatoryFields = ['title', 'imgSrc', 'activityType', 'description', 'challengePointValue'];

    if(isLoading) {
      return <Waiting />
    }

    return (
      <Layout>
        <ChallengeTitle>{"Create Challenge Library"}</ChallengeTitle>
        <FormContainer>
          <CreateChallengeForm
            isSurvivorChallenge={false}
            isGroupChallenge={false}
            history={this.props.history}
            MandatoryFields={MandatoryFields}
            createChallenge={createChallenge}
            survivorChallengeData={this.state.survivorChallengeData}
            role={role}
            createLibrary={createLibrary}
            createActivityChallenge={createActivityChallenge}
          />
        </FormContainer>
        {/* <FeaturedEventsContainer>
          <FeaturedChallenges history={history}/>
        </FeaturedEventsContainer> */}
      </Layout>
    )
  }
}

CreateChallenge.propTypes = {
  history: PropTypes.object.isRequired,
  createChallenge: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  challengeData: PropTypes.object,
  getChallengeData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  createActivityChallenge: PropTypes.func,
  createLibrary: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  error: state.challenges.createChallengeError,
  isLoading: state.challenges.isLoading,
  challengeData: state.challenges.challengeData

});

const mapDispatchToProps = (dispatch) => ({
  getChallengeData: () => dispatch(getChallengeData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateChallenge));
