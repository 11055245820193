import styled, { css } from 'styled-components';
import { Dropdown, DropdownButton } from "react-bootstrap";

import { StyledInput } from '../CreateEvent/styles';

const ActivityTypeContainer = styled.div`
  width: ${({ width }) => width ? width : '40% !important'};
  padding: 0;
  float: ${({float}) => float ? float : 'none'};
  margin: ${({margin}) => margin ? margin : '0px'};
  > span {
    width: ${({ width }) => width && '100%' };
  }
  > div {
    width: ${({ noChildWidth }) => noChildWidth ? '100%' : '95%' };
    max-width: ${({ noChildWidth }) => noChildWidth ? 'unset' : '310px' };
    margin-right:  ${({ noChildWidth }) => noChildWidth ? '0px' : '5%' };
    display: ${({ display }) => display ? 'flex' : 'block'};
    float: left;
  }

  @media (max-width: 550px) {
    ${({ challengeMode }) => challengeMode && css`
      margin-top: 15px;
    `}
  }
  
  @media (max-width: 640px) {
    width: 100% !important;
    margin-top: 0
  }

  .dropdown-menu {
    width: 100%;
    height: 280px;
    overflow: scroll;
  }
`;

const ActivityDropdown = styled(DropdownButton)`
  // padding: 12px 10px;
  width: 100%;
  text-align: left;
  // margin-top: 15px;
  color: rgb(153,153,153);
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible !important;
  .dropdown-toggle::after {
    ${'' /* margin-left: 23.5em;
    margin-top:5px; */}
    color:black !important;
  }
  button{
    text-align: left;
    width: 100%;
    height: 50px;
    outline: none;
    background-color: white;
    border-color: rgb(204, 204, 204);
    box-shadow: none;
    color: rgb(153, 153, 153);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::after{
      float: right;
      right: 17px; // 20px; 
      top: 21px; // 25px;
      position: absolute;
    }
    &:hover, &:active, &:focus{
      outline: none;
      background-color: white;
      border-color: rgb(204, 204, 204);
      box-shadow: none;
      color: rgb(153, 153, 153);
    }
    &:active:focus{
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c;
    }
  }
  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
    height:100px;
    overflex:scroll;
  }

  .btn:disabled{
    outline: none;
    background-color: white;
    border-color: rgb(204, 204, 204);
    box-shadow: none;
    color: rgb(153, 153, 153);
  }
  
  .btn.show {
    color: #333 !important;
    background-color: #d4d4d4 !important;
    border-color: #8c8c8c !important;
  }

  .dropdown-menu .show{
    position: ${ ({isCreateRecipe}) => {isCreateRecipe && "relative !important"} };
  }
  .dropdown-menu{
    display: ${({ isEditEvent }) => isEditEvent ? 'none !important' : '' };
  }

  .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
  }

  .dropdown-item.active:hover {
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
  }

  .dropdown-item:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }
`;

const NewActivityDropdown = styled(Dropdown)`
  padding: 12px 0px;
  width: 100%;
  text-align: left;
  margin-top: 15px;
  color: rgb(153, 153, 153);
  text-transform: capitalize;
  white-space: nowrap;
  
  button {
    width: 100%;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    width: 100%;
    height: 50px;
    outline: none;
    background-color: white;
    border-color: rgb(204, 204, 204);
    box-shadow: none;
    color: rgb(153, 153, 153);
    &:hover,
    &:active,
    &:focus {
      outline: none;
      background-color: white;
      color: rgb(153, 153, 153);
    }
    &:active {
      color: #333;
      background-color: #d4d4d4;
      border-color: rgb(204, 204, 204);
    }
    &:first-child:focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: rgb(204, 204, 204);
    }
    &:first-child:active {
      color: #333;
      background-color: #d4d4d4;
      border-color: rgb(204, 204, 204);
    }
    &::after {
      float: right;
      right: 20px;
      top: 30px;
      position: absolute;
    }
  }

  .dropdown-toggle::after {
    ${'' /* margin-left: 23.5em;
    margin-top:5px; */}
    color:black;
  }
  .dropdown-menu {
    width: 70%;
    flex-direction: column;
    .dropdown-item {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;
      &:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }
      &:active {
        color: #fff;
        text-decoration: none;
        background-color: #337ab7;
      }
    }
  }
  .dropdown-item.active {
    color: #fff !important;
    text-decoration: none !important;
    background-color: #337ab7 !important;
  }
`;

const SurvivorAverage = styled.div`
  width: auto;
  margin-right: 5%;
  max-width: 160px;
  display: inline-block;
  > input {
    margin-top: 15px;
    height: 46px;
  }

  @media (max-width: 992px) {
    width: 45% !important;
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const CustomInput = styled(StyledInput)`
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  height: 50px;
  font-size: 15px;
  padding: 0 20px;
  color: rgb(153,153,153);
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SurvivorChallengeSection = styled.div`
  width: 60% !important;
  display: inline-block;

  @media (max-width: 550px) {
    width: 100% !important;
    margin-top: 15px;
  }
`;

export { ActivityTypeContainer, ActivityDropdown, SurvivorAverage, CustomInput, SurvivorChallengeSection, NewActivityDropdown };