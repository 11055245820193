import styled from 'styled-components';

import { PageTitle } from '../CreateEvent/styles';

const ChallengeTitle = styled(PageTitle)`
  width: 100%;
  padding: ${({ padding }) => padding && padding};
`;

const FeaturedEventsContainer = styled.div`
  width: calc(35% - 30px);
  margin-left: 30px;
  float: right;

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
`;

export { ChallengeTitle, FeaturedEventsContainer };